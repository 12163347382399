import { Auth } from 'AuthBundle'
import { RefreshToken } from 'Bundles/AuthBundle/services/RefreshToken.js'
import { ViewServices } from './ViewServices.js'
import { Snackbar } from 'InterfaceBundle'

/**
 * Real time communication from front to back using sockets
 */
export const Realtime = {
	socket: null,
	ready: false,
	authCb: false,
	credentialsChanging: false,
	_cbs: [],
	_nextId: 1,
	_closing: false,
	_opening: false,
	_ackCbs: {},
	_emitStack: [],
	_sendStack: [],
	_closeEventListener: (e = null) => {
		if(e && e.code && e.code === 4666) RefreshToken.refresh()
		else Realtime._close()
	},
	_messageEventListener: d => Realtime._handleMessage(d),

	_initialize() {
		if(this._opening) return
		this._opening = true
		if(!this.socket) {
			if(Auth.isAuthenticated()) {
				let url = location.origin.replace('http://', 'ws://').replace('https://', 'wss://') + '/rt/'
				url += '?token=' + Auth.getToken()
				if(Auth.isImpersonating()) url += '&impersonate=' + Auth.isImpersonating()
				if(this.socket) this.socket.removeEventListener('message', this._messageEventListener)
				this.socket = new WebSocket(url)
				this.socket.addEventListener('message', this._messageEventListener)
				this.socket.addEventListener('error', () => {
					this.socket.removeEventListener('close', this._closeEventListener)
					setTimeout(() => this._close(), 1000)
				})
				this.socket.addEventListener('close', this._closeEventListener)
			}
		}
		if(!this.authCb) {
			this.authCb = true
			Auth.events.logout.subscribe(() => this._close(true, false))
			Auth.events.credentialsChanged.subscribe(() => {
				this.credentialsChanging = true
				this._close(true, true)
			})
		}
		this._opening = false
	},

	_close(close = false, reconnect = true) {
		if(this._closing) return
		this._closing = true
		if(this.socket) {
			this.socket.removeEventListener('message', this._messageEventListener)
			if(close) {
				this.socket.removeEventListener('close', this._closeEventListener)
				this.socket.close()
			}
			this.ready = false
			this.socket = null
			for(let cb of this._cbs) {
				if(cb.event == '_status' && !this.credentialsChanging) cb.cb(false)
			}
		}
		if(reconnect && Auth.isAuthenticated()) this._initialize()
		this._closing = false
	},

	_authenticated() {
		if(this.socket) {
			this.credentialsChanging = false
			this.ready = true
			for(let item of this._emitStack) this.emit(item.event, item.data)
			this._emitStack = []
			for(let item of this._sendStack) this.socket.send(item)
			this._sendStack = []
			for(let cb of this._cbs) {
				if(cb.event == '_status') cb.cb(true)
			}
		}
	},

	_handleMessage(data) {
		if(data.data == '_authenticated') this._authenticated()
		else if(['_ackok', '_ackko'].includes(data.data.substr(0, 6))) {
			let uid = data.data.substr(6)
			let ok = data.data.substr(0, 6) == '_ackok'
			if(!ok) Snackbar.open({text: 'An error occurred', error: true})
			if(this._ackCbs[uid]) {
				for(let cb of this._ackCbs[uid]) cb(ok)
				delete this._ackCbs[uid]
			}
		}
		else {
			let messageContent = JSON.parse(data.data)
			for(let cb of this._cbs) {
				if(cb.event == messageContent.event) cb.cb(messageContent.data)
			}
		}
	},

	_cancel(id) {
		this._cbs = this._cbs.filter(c => c.id != id)
	},

	status() {
		return this.listen('_status')
	},

	/**
	 * Listen to event
	 * @param  {string}  event
	 * @return {Promise}
	 */
	listen(event) {
		this._initialize()
		let id = this._nextId
		this._nextId++
		return {
			then: cb => this._cbs.push({event: event, cb: cb, id: id}),
			unsubscribe: () => this._cancel(id)
		}
	},

	/**
	 * Emit event
	 * @param  {string} event
	 * @param  {object} data
	 * @return {Promise}
	 */
	emit(event, data = null) {
		this._initialize()
		let uid = Date.now().toString(36) + Math.random().toString(36).substr(2)
		let request = JSON.stringify({
			event: event,
			data: data,
			_uid: uid
		})
		if(this.ready) this.socket.send(request)
		else this._sendStack.push(request)
		return {
			then: cb => {
				if(!this._ackCbs[uid]) this._ackCbs[uid] = []
				this._ackCbs[uid].push(cb)
			},
			_uid: uid
		}
	}
}

ViewServices.realtime = Realtime