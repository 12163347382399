function blFlexDirectiveUpdate(el, bindings) {
	let grids = []
	const gridValidators = ['s', 'm', 'l', 'xl', 'all']
	if(bindings.modifiers) {
		for(let key in bindings.modifiers) {
			if(!gridValidators.includes(key)) throw new Error('Invalid grid value "' + key + '", should be one of : [' + gridValidators.join(', ') + ']')
			if(bindings.modifiers[key]) grids.push(key)
		}
	}
	if(!grids.length) grids = ['all']

	//Remove previous classes
	el.classList.forEach(className => {
		for(let grid of grids) {
			if(className.substr(0, 9) == 'bl-flex-' + grid) el.classList.remove(className)
		}
	})

	for(let grid of grids) {
		if(bindings.value) {
			el.style.setProperty('--bl-flex-value-' + grid, bindings.value + '%')
			el.classList.add('bl-flex-' + grid)
		}
		else el.classList.add('bl-flex-' + grid + '-full')
	}
}

export default {
	mounted(el, bindings) {
		blFlexDirectiveUpdate(el, bindings)
	},
	updated(el, bindings) {
		blFlexDirectiveUpdate(el, bindings)
	}
}