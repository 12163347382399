<template>
	<div style="position: relative;">
		<BlSelect ref="select" :data='data' v-model="value" @change="setFilter()" :loading="loading" :combo="true" />
		<span v-if="value && value.length" class="suffix material-icons clear" @click="clear()" style="visibility: visible; margin-top: 10px;">remove_circle_outline</span>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'

export default {
	name: 'BlListFilterSelectManyToOne',
	props: ['initialValue', 'metadata', 'options'],
	emits: ['blListFilterChange'],
	data() {
		return {
			value: [],
			loading: true,
			data: {}
		}
	},
	inject: ['blList'],
	created() {
		this.value = this.initialValue
		if(!this.value) this.value = []
		this.loadData()
		this.defaultFilterChangeSub = this.blList.defaultFiltersChange.subscribe(() => {
			if(!this.options.filters) {
				this.clear()
				this.loadData()
			}
		})
	},
	unmounted() {
		this.defaultFilterChangeSub.unsubscribe()
	},
	methods: {
		setFilter() {
			if(this.value && this.value.length) this.$emit('blListFilterChange', [[this.metadata.field.name + '.id', 'IN', this.value], this.value])
			else this.$emit('blListFilterChange', null)
		},
		clear() {
			this.value = []
			this.setFilter()
		},
		loadData() {
			this.loading = true
			this.data = {}
			let req = {
				data: {
					model: this.metadata.model.name,
					fields: [{name: this.metadata.field.name + '.__toString'}, {name: this.metadata.field.name + '.id'}],
					limit: -1,
					groupBy: [this.metadata.field.name],
					route: false,
					metadata: false
				}
			}
			if(this.options.filters) req.data.filters = this.options.filters
			else if(this.blList.defaultFilters) req.data.filters = this.blList.defaultFilters

			Api.post('api/', req).then(resp => {
				this.data = {}
				this.data['<em style="padding-right: 5px; color: var(--bl-legend);">' + this.$t('model.list.filterSelectAll') + '</em>'] = null
				resp.data.data = resp.data.data
					.filter(i => i[this.metadata.field.name + '.id'])
					.sort((a, b) => a[this.metadata.field.name + '.__toString'].toUpperCase() > b[this.metadata.field.name + '.__toString'].toUpperCase() ? 1 : -1)
				for(let item of resp.data.data) this.data[' ' + item[this.metadata.field.name + '.__toString']] = item[this.metadata.field.name + '.id']//Concatenate with space to guarantee all entry is first
				this.loading = false
			})
		}
	}
}
</script>

<style scoped lang="scss">
	span.suffix.clear {
		position: absolute;
		left: 100%;
		margin-left: -33px;
		top: 0;
		background-color: var(--bl-surface);
		cursor: pointer;
	}
</style>