<template>
	<div class="loginContainer">
		<div class="bl-card loginCard" :class="{errored: erroredAnimation}">
			<img :src="image" />
			<form @submit="formSubmit($event)">
				<BlSelect v-if="providerSelector" :data="providerSelector" :label="t('auth.login.loginWith')" v-model="provider" />
				<div v-bl-input>
					<label>{{ t('auth.login.username') }}</label>
					<input id="usernameInput" type="text" v-model="username" required autocomplete="username" />
				</div>
				<div v-bl-input>
					<label>{{ t('auth.login.password') }}</label>
					<input id="passwordInput" :type="mode" v-model="password" required autocomplete="current-password" />
					<span class="suffix material-icons" @click="toggleMode()" style="visibility: visible;">{{ mode == 'password' ? 'visibility' : 'visibility_off' }}</span>
				</div>
				<br />
				<BlButton :label="t('auth.login.login')" class="primary" :loading="currentRequest" type="submit"></BlButton>
				<div class="bl-card error" v-if="errored">{{ errorMessage }}</div>
			</form>
		</div>
	</div>
</template>

<script>

import { Login } from 'AuthBundle'
import { Api } from 'ModelBundle'
import { Variables, ViewServices, Router, ViewLoader } from 'InterfaceBundle'

export default {
	name: 'AuthLoginForm',
	data() {
		return {
			mode: 'password',
			loaded: false,
			image: Variables.getIcon(),
			username: '',
			password: '',
			errored: false,
			errorMessage: null,
			erroredAnimation: false,
			currentRequest: false,
			provider: null,
			providerSelector: null,
			variablesLoaded: null,
			i18n: {}
		}
	},
	methods: {
		formSubmit(event) {
			if(!this.loaded) return
			event.preventDefault()
			this.currentRequest = true
			this.errored = false
			Login.login(this.username, this.password, this.provider).once(resp => {
				if(resp.valid) {
					//Redirect to home page
					if(ViewLoader.isReady) this.redirect()
					else ViewLoader.ready.subscribe(() => this.redirect())
					this.currentRequest = false
				}
				else {
					try {
						resp.err.text().then(resp => this.errorMessage = resp)
					} catch(e) {
						this.errorMessage = this.t('auth.login.error')
					}
					this.password = ''
					if(document.getElementById('passwordInput')) document.getElementById('passwordInput').focus()
					this.currentRequest = false
					this.errored = true
					this.erroredAnimation = true
					setTimeout(() => this.erroredAnimation = false, 800)
				}
			})
			return false
		},
		toggleMode() {
			this.mode = this.mode == 'password' ? 'text' : 'password'
		},
		redirect() {
			if(ViewServices.interfaceData.homePage) setTimeout(() => {
				if(this.redirectTo) Router.navigateUri(this.redirectTo)
				else Router.navigateUri(ViewServices.interfaceData.homePage)
			})
		},
		t(key) {
			if(this.i18n[key]) return this.i18n[key]
			else if(ViewLoader.data?.i18n) return this.$t(key)
			return ''
		}
	},
	mounted() {
		if(window.location.pathname != '/' && window.location.pathname.length) this.redirectTo = window.location.pathname
		document.getElementById('usernameInput').focus()
		this.variablesLoaded = Variables.loaded.subscribe(() => {
			this.image = Variables.getIcon()
			this.i18n = Variables.i18n
		})
		Api.request('GET', 'auth/authentication-providers/', {}, {}, false).then(providers => {
			if(providers.length > 1) {
				this.providerSelector = {}
				for(let provider of providers) {
					this.providerSelector[provider.label] = provider.id
					if(provider.default) this.provider = provider.id
				}
			}
			this.loaded = true
		})
	},
	unmounted() {
		this.variablesLoaded.unsubscribe()
	}
}
</script>

<style scoped lang="scss">
div.bl-input .suffix.material-icons {
	font-size: 20px;
	margin-top: 12px;
	cursor: pointer;
}

#passwordInput {
	padding-right: 30px;
	width: calc(100% - 22px);
}

.loginContainer {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loginCard {
	z-index: 2;
	width: 320px;
	margin:  auto;
	text-align: center;
	padding:  20px;

	div.bl-input {
		margin-bottom: 15px;
	}
}

.loginCard > form > button {
	width: 100%;
	margin-top: -12px;
	padding-right: 14px !important;

	svg {
		top: 5px !important;
	}
}

.loginCard > img {
	max-width: 100%;
	max-height: 150px;
}

.loginCard.errored {
	animation: shake 0.7s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%, 80% {
		transform: translate3d(2px, 0, 0);
	}

	30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%, 60% {
		transform: translate3d(4px, 0, 0);
	}
}

:deep .blSelectContainer {
	margin-bottom: 15px;
	z-index: 2;
	text-align: left;
}
</style>