<template>
	<component :is="component" :field="field" :tabindex="tabindex"></component>
</template>

<script>
import { EventEmitter } from 'InterfaceBundle'
export default {
	name: 'BlStandaloneField',
	props: ['type', 'label', 'modelValue', 'required', 'options', 'tabindex'],
	emits: ['update:modelValue', 'change', 'touched'],
	data() {
		return {
			component: null,
			touched: false,
			field: {
				value: this.modelValue,
				isErrored: () => !this.modelValue && this.required,
				getTouched: () => this.touched,
				setTouched: () => {
					this.touched = true
					this.$emit('touched', this.touched)
				},
				setValue: v => {
					this.$emit('update:modelValue', v)
					this.$emit('change')
				},
				label: this.label + (this.required ? '*' : ''),
				emitter: {
					change: new EventEmitter(),
					focus: new EventEmitter(),
					beforeSubmit: new EventEmitter(),
					beforeValidation: new EventEmitter(),
					dataChange: new EventEmitter()
				},
				options: this.options ? this.options : {},
				getQualifiedName: () => this.qualifiedName
			}
		}
	},
	watch: {
		modelValue(newVal) {
			this.field.value = newVal
			this.field.emitter.change.emit()
		},
		options(newVal) {
			this.field.options = newVal
		},
		required() {
			if(this.required && this.field.label.substr(-1) != '*') this.field.label += '*'
			else if(!this.required) this.field.label = this.label
		}
	},
	created() {
		this.qualifiedName = crypto.randomUUID()
		this.component = 'BlFormField' + this.type.charAt(0).toUpperCase() + this.type.slice(1)
	}
}
</script>

<style scoped lang="scss">
	:deep.bl-input, :deep.bl-checkbox-field {
		margin-bottom: 10px;
	}
</style>
