function blLayoutDirectiveUpdate(el, bindings) {
	let grids = []
	const gridValidators = ['s', 'm', 'l', 'xl', 'all']
	if(bindings.modifiers) {
		for(let key in bindings.modifiers) {
			if(!gridValidators.includes(key)) throw new Error('Invalid grid value "' + key + '", should be one of : [' + gridValidators.join(', ') + ']')
			if(bindings.modifiers[key]) grids.push(key)
		}
	}
	if(!grids.length) grids = ['all']

	let parts = bindings.value ? bindings.value.split(' ') : []

	const validators = [
		['row', 'column'],//Layouts
		['start', 'center', 'end', 'around', 'between', 'evenly'],//Row
		['start', 'center', 'end', 'stretch'],//Col
		['no-wrap', 'wrap'],//Wrap
		['1', '0', '2', '3', '4']//Gap
	]
	const classPrefix = [
		'layout',
		'row',
		'col',
		'wrap',
		'gap'
	]
	if(parts.length && !validators[0].includes(parts[0])) parts.unshift('row')

	//Remove previous classes
	el.classList.forEach(className => {
		for(let grid of grids) {
			if(className.substr(0, 12) == 'bl-layout-' + grid + '-') el.classList.remove(className)
		}
	})

	for(let i in validators) {
		if(parts[i] && !validators[i].includes(parts[i])) throw new Error('Invalid layout value "' + parts[i] + '", should be one of : [' + validators[i].join(', ') + ']')
		else if(!parts[i]) parts[i] = validators[i][0]
		for(let grid of grids) el.classList.add('bl-layout-' + grid + '-' + classPrefix[i] + '-' + parts[i])
	}
}

export default {
	mounted(el, bindings) {
		blLayoutDirectiveUpdate(el, bindings)
	},
	updated(el, bindings) {
		blLayoutDirectiveUpdate(el, bindings)
	}
}