<template>
	<div style="position: relative;">
		<div v-bl-input :class="{errored: (invalidDate || field.isErrored()) && field.getTouched()}" class="__suffix">
			<label>{{ field.label }}</label>
			<input ref="field" type="text" v-mask="mask" v-model="modelStr" @focus="handleFocus()" @change="fieldChange()" @keyup="autoComplete()" :tabindex="tabindex" />
			<span class="suffix material-icons" @click="toggleCalendar()" style="visibility: visible;">calendar_today</span>
		</div>
		<div class="nativeInput" v-if="mobile"><input type="datetime-local" ref="nativeInput" @change="setModelFromNative()" /></div>
		<BlCalendarInput v-else @change="setCalendarValue($event)" :value="model" :format="getFormat()" :opened="showCalendar" @openedChange="openedChange" :disableAnimation="disableCalendarAnimation">
			<div v-if="showCalendar" class="timeSelector bl-card" @click.stop="">
				<ul class="bl-light-scroll">
					<li v-for="hour in hours" :key="hour" :class="{active: isActiveHour(hour)}" @click="setTime(hour, 'HH')">{{ hour }}</li>
				</ul>
				<ul class="bl-light-scroll">
					<li v-for="minute in minutes" :class="{bold: ['00', '15', '30', '45'].includes(minute), active: isActiveMinute(minute)}" :key="minute" @click="setTime(minute, 'mm')">{{ minute }}</li>
				</ul>
			</div>
		</BlCalendarInput>
	</div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { DateFormat, ViewServices, Variables } from 'InterfaceBundle'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default {
	name: 'BlFormFieldDatetime',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: this.field.value,
			modelStr: this.field.value,
			mask: null,
			showCalendar: false,
			hours: [],
			minutes: [],
			invalidDate: false,
			fieldChanged: false,
			mobile: Variables.mobile,
			disableCalendarAnimation: false
		}
	},
	methods: {
		toggleCalendar() {
			this.disableCalendarAnimation = false
			if(this.mobile) {
				this.$refs.nativeInput.focus()
				this.$refs.nativeInput.showPicker()
			}
			else {
				this.showCalendar = !this.showCalendar
				setTimeout(() => {
					this.$el.querySelectorAll('.timeSelector li.active').forEach(el => el.scrollIntoView({block: 'center', inline: 'center'}))
				})
			}
		},
		setCalendarValue(value) {
			let activeHour = this.hours.filter(h => this.isActiveHour(h))
			let activeMinute = this.minutes.filter(m => this.isActiveMinute(m))
			this.modelStr = value
			this.fieldChanged = true
			if(activeHour.length) this.setTime(activeHour[0], 'HH')
			if(activeMinute.length) this.setTime(activeMinute[0], 'mm')
			this.fieldChange()
		},
		/**
		 * Autocomplete year
		 */
		autoComplete() {
			if(!this.field.options.autocomplete || !this.modelStr) return
			let autocompleteMask = DateFormat.createMask(this.getFormat().replace('yyyy', '~'))
			autocompleteMask = this.modelStr + autocompleteMask.substr(this.modelStr.length)
			if(autocompleteMask.split('#')[0].includes('~') && autocompleteMask.includes('~')) {
				let now = new Date()
				let formattedStr = autocompleteMask.replace('~', now.getFullYear())
				let dateObject = dayjs(formattedStr, DateFormat.transformPHPFormatToJS(this.getFormat().substr(0, this.modelStr.length))).toDate()
				let days = (dateObject - now) / (1000 * 60 * 60 * 24)
				if(days > 180) this.modelStr = autocompleteMask.replace('~', now.getFullYear() - 1)
				else if(days < -180) this.modelStr = autocompleteMask.replace('~', now.getFullYear() + 1)
				else this.modelStr = formattedStr
			}
		},
		isActiveMinute(value) {
			return this.modelStr && dayjs(this.modelStr, DateFormat.transformPHPFormatToJS(this.getFormat())).toDate().getMinutes() == parseInt(value)
		},
		isActiveHour(value) {
			return this.modelStr && dayjs(this.modelStr, DateFormat.transformPHPFormatToJS(this.getFormat())).toDate().getHours() == parseInt(value)
		},
		setTime(value, type) {
			let valueMask = DateFormat.createMask(this.getFormat().replace(type, '~ '))
			let index = valueMask.indexOf('~')
			this.modelStr = this.modelStr.substr(0, index) + value + this.modelStr.substr(index + 2)
			this.fieldChange()
		},
		fieldChange() {
			this.disableCalendarAnimation = true
			let dateObject = dayjs(this.modelStr, DateFormat.transformPHPFormatToJS(this.getFormat()), true).toDate()
			this.fieldChanged = true
			this.invalidDate = false
			if(isNaN(dateObject.getTime())) {
				this.field.setValue(null)
				if(this.modelStr) this.invalidDate = true
			}
			else this.field.setValue(this.modelStr)
		},
		getFormat() {
			return this.field.options.format ? this.field.options.format : ViewServices.interfaceData.intl.date.datetimeformat
		},
		handleFocus() {
			if(this.mobile) {
				this.toggleCalendar()
				this.field.setTouched()
			}
		},
		setModelFromNative() {
			const date = this.$refs.nativeInput.value
			this.modelStr = date ? dayjs(date).format(DateFormat.transformPHPFormatToJS(this.getFormat())) : ''
			this.fieldChange()
		},
		setNativeValue() {
			if(this.mobile) this.$refs.nativeInput.value = this.modelStr ? dayjs.utc(this.modelStr, DateFormat.transformPHPFormatToJS(this.getFormat()), true).toDate().toISOString().slice(0, 16) : null
		},
		getNativeValue() {
			return this.modelStr ? dayjs(this.modelStr, DateFormat.transformPHPFormatToJS(this.getFormat()), true).toDate() : null;
		},
		openedChange(ev) {
			this.showCalendar = ev
			this.disableCalendarAnimation = false
		}
	},
	created() {
		for(let i = 0; i < 24; i++) this.hours.push((i < 10 ? '0' : '') + i)
		for(let i = 0; i < 60; i++) this.minutes.push((i < 10 ? '0' : '') + i)
		this.mask = DateFormat.createMask(this.getFormat())
		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())
		this.field.emitter.change.subscribe(() => {
			this.model = this.field.value
			if(!this.fieldChanged) this.modelStr = this.field.value
			this.setNativeValue()
			this.fieldChanged = false
		})
	},
	mounted() {
		this.setNativeValue()
	},
	directives: {
		mask
	}
}
</script>

<style scoped lang="scss">
	div.bl-input .suffix.material-icons {
		font-size: 20px;
		margin-top: 12px;
		cursor: pointer;
	}

	.timeSelector {
		position: absolute;
		margin-left: 286px;
		height: 296px;
		z-index: 2;
		display: flex;
		margin-top: 4px;

		ul {
			overflow-y: auto;
			overflow-x: hidden;
			list-style: none;
			margin: 0;
			padding: 0 5px 0 0;

			li {
				padding: 7px 20px 7px 20px;
				cursor: pointer;
				transition: background-color .2s;
				border-radius: var(--bl-border-radius);
			}

			li.bold {
				font-weight: 500;
			}

			li:hover {
				background-color: var(--bl-background);
			}

			li.active {
				font-weight: 500;
				background-color: var(--bl-primary);
				color: var(--bl-on-primary);
			}
		}

		ul:first-child {
			margin-right: 10px;
		}
	}

	ul:hover {
		padding-right: 0;
	}

	.nativeInput {
		overflow: hidden;
		width: 0;
		height: 0;
	}
</style>
