<template>
	<div class="bl-chip" :style="{'--bl-chip-color': color ? color : 'var(--bl-legend)'}">
		<icon v-if="icon">{{ icon }}</icon>
		<span>{{ label }}</span>
	</div>
</template>

<script>
export default {
	props: ['label', 'color', 'icon'],
	name: 'BlViewRichChoice'
}
</script>

<style lang="scss" scoped>
</style>