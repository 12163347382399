<template>
	<div :class="{errored: field.isErrored() && field.getTouched(), focused: focused && !field.options.disabled, hasValue: field.value, disabled: field.options.disabled}" class="blFormFieldRichText">
		<label :class="{code: field.options.code}">{{ field.label }}</label>
		<div class="inputContainer" :class="{wysiwyg: !field.options.code}">
			<textarea v-if="field.options.code" ref="fieldInput" v-model="model" :tabindex="tabindex" />
			<BlWysiwyg :compact="field.options.compact" v-model="model" @change="field.setValue(model)" ref="wysiwyg" @blur="focused = false; field.setTouched()" @focus="focused = true;" v-else />
		</div>
	</div>
</template>

<script>
import { ScriptLoader, Variables } from 'InterfaceBundle'

export default {
	name: 'BlFormFieldRichtext',
	props: ['field', 'tabindex'],
	data() {
		return {
			focused: false,
			model: this.field.value,
			editor: null
		}
	},
	mounted() {
		if(typeof this.model === 'object') this.model = JSON.stringify(this.model, null, '\t')
		if(this.field.options.code) {
			if(this.field.options.code.mode == 'json') {
				try {
					this.model = JSON.stringify(JSON.parse(this.model), null, '\t')
				}
				catch(e) {
					//
				}
			}
			ScriptLoader.importJS('https://static.mixsuite.fr/lib/ace-latest/ace.js').then(() => {
				this.editor = window.ace.edit(this.$refs.fieldInput)
				let height = this.field.options.code.height ? this.field.options.code.height : 1
				let options = this.field.options.code.native ? this.field.options.code.native : {}
				if(!this.field.options.code.native?.minLines) options.minLines = height
				if(!this.field.options.code.native?.maxLines) options.maxLines = height
				options.showPrintMargin = false
				this.editor.setOptions(options)
				if(this.field.options.code.theme) this.editor.setTheme('ace/theme/' + this.field.options.code.theme)
				//Auto theme
				else this.editor.setTheme('ace/theme/' + (Variables.theme == 'light' ? 'chrome' : 'monokai'))
				if(this.field.options.code.mode) {
					this.editor.session.setMode('ace/mode/' + this.field.options.code.mode)
				}
				this.editor.session.on('change', () => this.field.setValue(this.editor.getValue()))
				this.editor.on('focus', () => this.focused = true)
				this.editor.on('blur', () => {
					this.focused = false
					this.field.setTouched()
				})
				this.field.emitter.focus.subscribe(() => this.editor.focus())
				this.editor.setReadOnly(this.field.options.disabled)
			})
		}
		else {
			this.field.emitter.change.subscribe(() => this.model = this.field.value)
			this.field.emitter.focus.subscribe(() => this.$refs.wysiwyg.$refs.contentEditable.focus())
		}
	}
}
</script>

<style scoped lang="scss">
label {
	color: var(--bl-legend);
	position: absolute;
	background-color: var(--bl-surface);
	border-radius: 4px;
	z-index: 8;
	margin: 8px 0 0 7px;
	transition: font-size 0.15s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1), margin-top 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.2s;
}

.errored {
	label {
		color: var(--bl-error);
	}

	.inputContainer, .inputContainer.wysiwyg {
		box-shadow: 0 0 0 2px var(--bl-error);
	}
}

.inputContainer {
	box-shadow: 0 0 0 2px var(--bl-border);
	border-radius: var(--bl-border-radius);
	overflow: hidden;
}

.inputContainer.wysiwyg {
	box-shadow: 0 0 0 1px var(--bl-border);
}

.inputContainer:not(.wysiwyg) {
	padding-top: 10px;
}

.blFormFieldRichText {
	margin-top: 15px;
	position: relative;
}

.blFormFieldRichText.disabled {
	:deep .ace_scroller {
		background-color: var(--bl-background);

		.ace_content {
			opacity: .7;
		}
	}

	:deep .contenteditable {
		pointer-events: none;
	}

	:deep .toolbar {
		display: none;
	}
}

:is(.hasValue, .focused) label, label.code {
	font-size: 10.5px;
	margin-left: 5px;
	margin-top: -7px;
	padding: 0 4px 0 2px;
}

.focused {
	label {
		color: var(--bl-primary);
	}

	.inputContainer, .inputContainer.wysiwyg {
		box-shadow: 0 0 0 2px var(--bl-primary);
	}
}

:deep .ace_editor:not(.ace_focus) .ace_active-line {
	display: none;
}
</style>