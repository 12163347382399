<template>
	<div style="position: relative;">
		<BlSelect :data='data' v-model="value" @change="setFilter()" :combo="true" />
		<span v-if="value.length" class="suffix material-icons clear" @click="clear()" style="visibility: visible; margin-top: 10px;">remove_circle_outline</span>
	</div>
</template>

<script>
export default {
	name: 'BlListFilterSelectRichChoiceList',
	props: ['initialValue', 'metadata'],
	emits: ['blListFilterChange'],
	data() {
		return {
			value: [],
			data: {}
		}
	},
	created() {
		this.value = this.initialValue
		if(!this.value) this.value = []
		for(let index in this.metadata.field.options.choices) {
			let choice = this.metadata.field.options.choices[index]
			if(typeof choice === 'string') choice = {label: choice}
			if(choice.disabled) continue
			if(this.metadata.field.options.translateChoices) choice.label = this.$t(choice.label)
			let label = '<div class="richChoiceSelectItem"'
			if(choice.color) label += ' style="--bl-rich-choice-select-color: ' + choice.color + '"'
			label += '>'
			if(choice.icon) label += '<icon>' + choice.icon + '</icon>'
			label += choice.label + '</div>'
			this.data[label] = index
		}
	},
	methods: {
		setFilter() {
			if(this.value && this.value.length) this.$emit('blListFilterChange', [[this.metadata.field.name, 'IN', this.value], this.value])
			else this.$emit('blListFilterChange', null)
		},
		clear() {
			this.value = []
			this.setFilter()
		}
	}
}
</script>

<style scoped lang="scss">
	span.suffix.clear {
		position: absolute;
		left: 100%;
		margin-left: -33px;
		top: 0;
		background-color: var(--bl-surface);
		cursor: pointer;
	}
</style>